<template>
  <div class="container home-container">
    <div class="home-page" id="home">
      <div class="home-box">
        <div class="home-top">
          <div class="home-title">让工作效率腾飞的智能伙伴</div>
          <div class="home-text">
            悦联AI-旨在为企业提升工作效率，以价值引导、自我塑造、综合评价为途径，用易于接受和理解的方式助力人才企业管理数字化、精简化、智慧化变革。
          </div>
        </div>
        <div>
          <el-button @click="goStart" class="start-btn">立即开始 <i class="el-icon-right"></i></el-button>
        </div>
      </div>
      <div class="our-img">
        <img src="../assets/images/p14.png" alt="" />
      </div>
    </div>
    <div class="cetner-box" id="server">
        <div class="center-title">
          智能驱动，效率领航
        </div>
        <div class="center-two-tiltle">
          <!-- 全新AI数字化体系  <span class="key-text">4种</span>核心技术突破  0成本学习AI办公 -->
          全新AI数字化体系  AIGC核心技术突破  AI赋能企业精准办公
        </div>
        <div class="center-tab">
          <div class="center-tab-item" @click="clickTab(1)" @mouseenter="clickTab(1)">
            <img v-show="tabindex === 1" src="../assets/images/p5.png">
            <img v-show="tabindex !== 1" src="../assets/images/p9.png">
          </div>
          <div class="center-tab-item" @click="clickTab(2)" @mouseenter="clickTab(2)">
            <img v-show="tabindex === 2" src="../assets/images/p6.png">
            <img v-show="tabindex !== 2" src="../assets/images/p10.png">
          </div>
          <div class="center-tab-item" @click="clickTab(3)" @mouseenter="clickTab(3)">
            <img v-show="tabindex === 3" src="../assets/images/p7.png">
            <img v-show="tabindex !== 3" src="../assets/images/p11.png">
          </div>
          <div class="center-tab-item" @click="clickTab(4)" @mouseenter="clickTab(4)">
            <img v-show="tabindex === 4" src="../assets/images/p8.png">
            <img v-show="tabindex !== 4" src="../assets/images/p12.png">
          </div>
        </div>
        <div class="center-box">
            <div class="all-box"  v-show="tabindex === 1">
              <div class="left-box">
                <div class="one">
                  <div class="num">01</div>
                  <div class="text">AI智能剪辑</div>
                </div>
                <div class="two">
                  <div class="two-title"><img src="../assets/images/gou.png">提高创作效率</div>
                  <div class="two-content">
                    对于视频创作者来说，传统的视频剪辑过程往往耗时费力，而AI智能剪辑能够在5分钟内处理大量的视频素材。例如，你有几个小时的原始视频素材，AI可以快速分析并提取关键片段，缩短剪辑时间，大大提升了短视频创作效率问题
                  </div>
                </div>
                <div class="two">
                  <div class="two-title"><img src="../assets/images/gou.png">精准内容识别</div>
                  <div class="two-content">对于视频本身AI可以自动识别出所需要对应的素材。利用先进的人工智能技术，自动分析视频素材，智能选择最佳镜头，生成吸引人的视频故事线。剪辑过程中确保输出的视频符合企业的品牌形象和标准，大幅减少制作的时间和成本，提高营销内容的生产效率。</div>
                </div>
              </div>
              <div class="right-box" key="imgae1">
                <img src="../assets/images/p2.png" alt="" key="img1"/>
              </div>
            </div>
            <div class="all-box"  v-show="tabindex === 2">
              <div class="left-box">
                <div class="one">
                  <div class="num">02</div>
                  <div class="text">AI智能IP规划</div>
                </div>
                <div class="two">
                  <div class="two-title"><img src="../assets/images/gou.png">多维度重点提炼</div>
                  <div class="two-content">
                    AI算法分析用户行为和竞争格局，帮助企业精准定位短视频IP方向；智能生成创意样本、视频规划方向、IP推广方案，确保短视频内容的连贯性和吸引力，还能提供IP价值评估和优化建议，助力企业高效打造具有影响力的品牌
                  </div>
                </div>
                <div class="two">
                  <div class="two-title"><img src="../assets/images/gou.png">IP价值最大化</div>
                  <div class="two-content">通过AI技术提供定制化的短视频内容矩阵方案。通过AI多维度剖析市场趋势和用户偏好，助力个人、企业用户从长远的角度管理短视频知识产权，通过持续的内容创新和品牌建设，实现IP价值最大化</div>
                </div>
              </div>
              <div class="right-box" key="imgae2">
                <img src="../assets/images/p4.png" alt="" key="img2"/>
              </div>
            </div>
            <div class="all-box"  v-show="tabindex === 3">
              <div class="left-box">
                <div class="one">
                  <div class="num">03</div>
                  <div class="text">AI短视频营销分析</div>
                </div>
                <div class="two">
                  <div class="two-title"><img src="../assets/images/gou.png">品牌价值增长</div>
                  <div class="two-content">
                    AI智能助手提供一键短视频营销方案分析服务，通过深入分析短视频的市场表现，提供数据支持和优化建议，帮助企业用户制定更有效的营销策略，提升视频的传播效果和转化率
                  </div>
                </div>
                <div class="two">
                  <div class="two-title"><img src="../assets/images/gou.png">内容创意优化</div>
                  <div class="two-content">专业洞察市面上竞争品牌创新策略，助力企业抢先入住短视频平台，获取百万流量。AI智能分析师能够轻松预测预测粉丝所爱的营销策略，进行产品分析与用户画像分析，为企业团队制作出更具吸引力的短视频营销方案，从而在竞争激烈的平台中脱颖而出</div>
                </div>
              </div>
              <div class="right-box" key="imgae3">
                <img src="../assets/images/p1.png" alt="" key="img3"/>
              </div>
            </div>
            <div class="all-box"  v-show="tabindex === 4">
              <div class="left-box">
                <div class="one">
                  <div class="num">04</div>
                  <div class="text">高效AI智能创作</div>
                </div>
                <div class="two two1">
                  <div class="two-title"><img src="../assets/images/gou.png">提升运营效率</div>
                  <div class="two-content">
                    AI人工智能写作专为内容生产者打造，一键生成高质量原创文章，旨在帮助创作者发散思维，提升工作效率
                  </div>
                </div>
                <div class="two two1">
                  <div class="two-title"><img src="../assets/images/gou.png">内容写作加速</div>
                  <div class="two-content">你可以通过智能AI写作自定义创作、1:1创作、专业创作以及内容分析，随时撰写各类型文案，让创作者轻松克服创作瓶颈，极大地提升内容创作的效率和品质</div>
                </div>
                <div class="two two1">
                  <div class="two-title"><img src="../assets/images/gou.png">提升企业生产力</div>
                  <div class="two-content">AI智能写作工具支持文案个性化定制，你可以根据自己的风格和目标受众进行调整，确保文案与个人品牌或频道形象保持一致。操作简单快捷，只需几步即可获得专业的口播文案，极大地节省了员工的时间和精力</div>
                </div>
              </div>
              <div class="right-box" key="imgae4">
                <img src="../assets/images/p3.png" alt="" key="img4"/>
              </div>
            </div>
        </div>
    </div>
    <div class="bottom-box">
      <div class="bottom-box-title">悦联AI</div>
      <div class="bottom-box-title bottom-box-title-bottom">开启您与智能数字时代交互新体验</div>
      <div class="bottom-content">
        <img src="../assets/images/imgbb.png" alt="" />
      </div>
    </div>
    <!-- <div class="background-img">
            <div class="area">
                <div class="ball ball1">
                  <img src="../assets/images/ren1.png" alt="">
                </div>
                <div class="ball ball2">
                  <img src="../assets/images/ren2.png" alt="">
                </div>
                <div class="ball ball3">
                  <img src="../assets/images/ren3.png" alt="">
                </div>
                <div class="ball ball4">
                  <img src="../assets/images/ren4.png" alt="">
                </div>
                <div class="ball ball5">
                  <img src="../assets/images/ren5.png" alt="">
                </div>
                <div class="ball ball6">
                  <img src="../assets/images/ren6.png" alt="">
                </div>
                <div class="ball ball7">
                  <img src="../assets/images/ren7.png" alt="">
                </div>
                <div class="ball ball8">
                  <img src="../assets/images/ren8.png" alt="">
                </div>
            </div>
        </div> -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabindex: 1,
      cardList: [
        {
          id: 1,
          label: '产品经理：沟通更方便了'
        },
        {
          id: 2,
          label: '部门主管：工作计划更清晰了'
        },
        {
          id: 3,
          label: '实习生：了解公司业务上手更容易了'
        },
        {
          id: 4,
          label: '人力：通知下发日程提醒人性化'
        },
        {
          id: 5,
          label: '财务：工作计划更清晰了'
        },
        {
          id: 6,
          label: '实习生：了解公司业务上手更容易了'
        },
        {
          id: 7,
          label: '部门主管：工作计划更清晰了'
        },
        {
          id: 8,
          label: '人力：通知下发日程提醒人性化'
        },
        {
          id: 9,
          label: '财务：工作计划更清晰了'
        }
      ]
    }
  },
  methods: {
    clickTab (id) {
      this.tabindex = id
    },
    goStart () {
      window.open('https://enjoylink.meteor.plus/', '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
.background-img{
  box-sizing: border-box;
        height:30rem;
        width:100%;
        margin-top:7vw;
        text-align: center;
        margin-bottom: 2rem;
        .area{
                width: 100%;
                height: 100%;
                position: relative;
            }
            .area:hover>.ball{
                animation-play-state:paused;//鼠标悬浮停止动画
            }
            .area>.ball{
                position: absolute;
                color: #E1EAFE;
                img {
                  width: 100%;
                  height: auto;
                  object-fit: fill;
                }
            }
            .area>.ball1{
                animation: anmiteX 10s cubic-bezier(0.6,0.3,0.3,0.6) -5s infinite alternate,
                anmiteY 10s cubic-bezier(0.6,0.3,0.3,0.6) 0s infinite alternate,
                scale 20s cubic-bezier(0.6,0.3,0.3,0.6) 0s infinite alternate;
            }
             .area>.ball2{
                animation: anmiteX 10s cubic-bezier(0.6,0.3,0.3,0.6) -7.5s infinite alternate,
                anmiteY 10s cubic-bezier(0.6,0.3,0.3,0.6) -2.5s infinite alternate,
                scale 20s cubic-bezier(0.6,0.3,0.3,0.6) -2.5s infinite alternate;
            }
            .area>.ball3{
                animation: anmiteX 10s cubic-bezier(0.6,0.3,0.3,0.6) -10s infinite alternate,
                anmiteY 10s cubic-bezier(0.6,0.3,0.3,0.6) -5s infinite alternate,
                scale 20s cubic-bezier(0.6,0.3,0.3,0.6) -5s infinite alternate;
            }
            .area>.ball4{
                animation: anmiteX 10s cubic-bezier(0.6,0.3,0.3,0.6) -12.5s infinite alternate,
                anmiteY 10s cubic-bezier(0.6,0.3,0.3,0.6) -7.5s infinite alternate,
                scale 20s cubic-bezier(0.6,0.3,0.3,0.6) -7.5s infinite alternate;
            }
            .area>.ball5{
                animation: anmiteX 10s cubic-bezier(0.6,0.3,0.3,0.6) -15s infinite alternate,
                anmiteY 10s cubic-bezier(0.6,0.3,0.3,0.6) -10s infinite alternate,
                scale 20s cubic-bezier(0.6,0.3,0.3,0.6) -10s infinite alternate;
            }
            .area>.ball6{
                animation: anmiteX 10s cubic-bezier(0.6,0.3,0.3,0.6) -17.5s infinite alternate,
                anmiteY 10s cubic-bezier(0.6,0.3,0.3,0.6) -12.5s infinite alternate,
                scale 20s cubic-bezier(0.6,0.3,0.3,0.6) -12.5s infinite alternate;
            }
            .area>.ball7{
                animation: anmiteX 10s cubic-bezier(0.6,0.3,0.3,0.6) -20s infinite alternate,
                anmiteY 10s cubic-bezier(0.6,0.3,0.3,0.6) -15s infinite alternate,
                scale 20s cubic-bezier(0.6,0.3,0.3,0.6) -15s infinite alternate;
            }
            .area>.ball8{
                animation: anmiteX 10s cubic-bezier(0.6,0.3,0.3,0.6) -22.5s infinite alternate,
                anmiteY 10s cubic-bezier(0.6,0.3,0.3,0.6) -17.5s infinite alternate,
                scale 20s cubic-bezier(0.6,0.3,0.3,0.6) -17.5s infinite alternate;
            }
             /* 动画 */
            /* 在X轴上的移动 */
            @keyframes  anmiteX{
                from{
                    left: 56vw;
                }
                to{
                    left: -1vw;
                }
            }
            /* 在轴上Y的移动 */
            @keyframes  anmiteY{
                from{
                    top: 7vw;
                }
                to{
                    top: 28vw;
                }
            }
            /* 对球进行放大和缩小在视觉上进行远近的模拟 */
            @keyframes  scale{
                0%{
                    transform: scale(0.8,0.8);
                    opacity: 0.5;
                }
                50%{
                    transform: scale(1.3,1.3);
                    opacity: 1;
                }
                100%{
                    transform: scale(0.8,0.8);
                    opacity: 0.5;
                }
            }
    }
.home-page {
  display: flex;
  position: relative;
  justify-content: flex-end;
  .home-box {
    height: 100%;
    position: absolute;
    top: 16%;
    left: 0%;
    display: flex;
    flex-direction: column;
    .home-top {
      position: relative;
      .home-title {
        color: #2a0e52;
        font-size: 1.25rem;
        margin-bottom: 7.9rem;
        font-weight: bold;
      }
      .home-text {
        position: absolute;
        top: 2.7rem;
        width: 100%;
        color: #000000;
        font-size: 0.625rem;
        font-weight: lighter;
        // margin-bottom: 4rem;
      }
    }
  }
  .our-img {
    margin-right: -5%;
    width: 60%;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
.cetner-box{
  margin-top: 4.68rem;
  .center-title {
    color: #2a0e52;
    font-size: 1.25rem;
        font-weight: bold;
        margin-bottom: 0.94rem;
  }
  .center-two-tiltle {
      font-size: 0.875rem;
      color: #4E5969;
      margin-bottom: 1.6875;
      .key-text {
        color: #9E5AFF;
      }
  }
  .center-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.25rem;
    margin-top: 1.625rem;
    .center-tab-item{
      cursor: pointer;
      flex: 1;
      color: #4E5969;
      font-size: 0.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.85rem;
      img {
        width: 100%;
        height: auto;
        object-fit: fill;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .center-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5.6rem;
    display: flex;
    .all-box {
      width: 100%;
      display: flex;
      .left-box {
        flex: 1;
        padding-right: 1.2rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-items: center;
        padding-top: 20px;
        .one {
          width: 100%;
          color: #2A0E52;
          font-size: 1rem;
          font-weight: bold;
          display: flex;
          margin-bottom: 1.25rem;
          border-bottom: 1px solid #D8D8D8;
          .num {
            margin-right: 0.315rem;
            color: #944FF7;
            border-bottom: 2px solid #944FF7;
          }
          .text {
          }
        }
        .two {
          width: 100%;
          margin-bottom: 2rem;
          .two-title {
            display: flex;
            align-items: center;
            color: #4E5969;
            font-size: 0.775rem;
            margin-bottom: 0.375rem;
            img {
              width: .875rem;
              height: .875rem;
              margin-right: .42rem;
              margin-left: .175rem;
            }
          }
          .two-content {
            color: #86909C;
            font-size: 0.525rem;
            line-height: 0.8rem;
            padding-left: 1.4688rem;
          }
        }
        .two1 {
          margin-bottom: 0.9rem;
        }
      }
      .right-box {
        width: 55%;
        // align-items: center;
        img {
          margin-top: 0.625rem;
          width: 100%;
          height: auto;
          object-fit: fill;
        }
      }
    }
  }
}
.bottom-box {
  .bottom-box-title {
    color: #2a0e52;
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: bold;
    font-size: 1.25rem;
  }
  .bottom-box-title-bottom{
    margin-bottom: 4rem;
  }
  .bottom-content {
    // background-image: url('../assets/images/bgg.jpg');
    // background-position: center center;
    // background-repeat: no-repeat;
    // background-attachment: fixed;
    // background-size: cover;
    height: 100%;
    display: flex;
    padding: 0 10%;
    flex-wrap: wrap;
    margin-top: 0.625rem;
    margin-bottom: 4.75rem;
    // padding: 0 10%;
    img {
      width: 100%;
      height: auto;
      object-fit: fill;
    }
  }
}

</style>
